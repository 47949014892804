// 社会责任
const socialResponsibilityText = {
  researchInnovationTitle: ['产品研发与创新'],
  researchInnovation: [`<p">公司坚持“通过筛查及早期检测预防及治愈癌症”的愿景，专注于开发及商业化癌症筛查产品，不断突破产品技术创新，持续投入前瞻性大规模临床试验。</p><p">以人才为核心，公司持续推进研发平台的建设以及研发团队的组织和发展。2023年，在原有杭州、北京两地研发中心的基础上，诺辉健康国际研发中心落成并投入使用，其中包括以国际最高标准打造的七千平方英尺高通量二代测序（NGS）基因检测实验室。此外，由诺辉健康首席科学家陈一友博士领衔，诺辉研究院在香港成立，并于2023年11月在北京设立研究分院，专注研究下一代分子诊断技术。</p><p">公司紧跟国内外知识产权相关政策标准的最新动态，依据《ISO56005：2020创新管理知识产权管理工具和方法指南》和《企业知识产权合规管理体系要求》（GB/T29490-2023）标准要求，完善知识产权管理体系。</p>`, 'Upcoming Events', '活動預告'],
  qualityManagementTitle: ['高标准的品质管理'],
  qualityManagement: [`公司严格遵守法律法规和行业标准，不断完善与优化优化质量管理体系，已通过ISO13485医疗器械质量管理体系认证、ISO9001质量管理体系认证并取得相应的认证证书。</br>
		公司制定了《质量控制程序》，明确规定产品从原料到过程、再到成品的全过程质量管理要求，从人员、机构、原料、方法、环境与设施方面严格进行产品质量管控。同时，公司积极推进全自动化产能建设，提升产品供应和样本检测能力。</br>
		公司产品均通过了体外诊断试剂医疗器械CE认证。</br>
		此外，公司每三年开展一次模拟召回工作，以提高产品出现危害性或潜在危害时的主动撤回执行力。自产品生产与销售以来，未发生因安全与健康理由而需召回产品的情况。
		`],
  customerServiceTitle: ['优质的客户服务'],
  customerService: [[{
    title: '客户服务',
    img: require('../../../assets/esg/customService.png'),
    text: `公司坚持以客户满意为目标，以“敬业、激情、团队、诚信、创新”为方针，加强公司服务质量，提升客户服务体验。基于公司癌症早筛产品居家检测的特性，以及医院、体检机构和互联网等不同渠道的服务需求，我们努力在产品设计、销售和使用的全流程，为客户提供完善优质的售前咨询和售后保障服务。</br>公司积极搭建客户沟通渠道，若客户对我们的产品或服务存在意见或反馈,可拨打我们的客户服务热线客户服务400-826-2300。在接到客户的反馈或投诉后，我们会根据《投诉处置控制程序》《投诉反馈处理标准化操作规程》等内部制度开展调查、分析和处理。`
  }, {
    title: '负责任营销',
    img: require('../../../assets/esg/responsibleMarket.png'),
    text: `公司以合规营销为底线，制定《广告和宣传合规管理制度》，建立对广告和宣传材料的双重审核管理流程，规范推广材料的使用和销售人员沟通行为，确保最终投放到市场中的推广材料以及销售人员的沟通内容满足《中华人民共和国广告法》等法律法规要求，符合客观医学事实，同时准确呈现产品相关信息。`
  }, {
    title: '资讯安全与客户隐私保护',
    img: require('../../../assets/esg/privacyPolicy.png'),
    text: `公司严格遵守《中华人民共和国个人信息保护法》《中华人民共和国计算机信息系统安全保护条例》等法律法规要求，建立《信息系统运维保障管理制度》《用户信息安全管理制度》《客户隐私资讯保密管理制度》等各项制度，切实保障公司信息安全、客户隐私不受侵犯。`
  }]],
  dutyProcureTitle: ['责任采购'],
  dutyProcure: [[{
    title: '供应商质量管理',
    text: `公司制定《采购控制程序》《招投标管理程序》《供应商评价与选择准则》《供应商审核制度》等，明确在供应商评价与准入、供应商入库、在库供应商审核和供应商退出等方面的管理要求和流程。`
  }, {
    title: '供应商社会、环境风险管理',
    text: `我们意识到供应商的社会和环境行为会影响我们自身经营活动的风险，因此我们逐渐重视并加强对供应商社会和环境风险的管理。公司制定了《环境健康安全管理体系-相关方安全管理制度》，通过自身行动降低包括供应商在内相关方的EHS风险。同时，我们亦制定(供应商行为守则》，对供应商在劳工标准、工作时间和报酬、结社自由、EHS、环境、知识产权、反腐败与道德等ESG方面提出明确要求。`
  }]],
  staffEquityWelfareTitle: ['员工权益与福利'],
  staffEquityWelfare: [[{
    title: '员工权益保障',
    text: `<strong>提供平等机会、杜绝歧视</strong></br>
			为全体员工提供平等机会并在就业、招聘、调岗、培训、薪酬和职业健康方面以才能为标准:杜绝歧视(种族、肤色、年龄、性别、性取向、出生地、残障、工会成员、政治观点或宗教信仰)。</br>
			<strong>坚持合法用工</strong></br>我们坚持合法用工，杜绝聘用童工和强制劳工。`
  }, {
    title: '员工福利与关爱',
    text: `公司为员工提供福利假期、商业保险、年度体检、生日礼金、文化特色礼盒等丰富福利，提升员工幸福感和对公司文化的认同感。</br>
			公司关注员工的身心健康，组织开展运动、家庭日活动等文体活动，建立员工心理健康关怀机制，并提供灵活办公机会，促进员工工作与生活平衡。`
  }, {
    title: '员工沟通',
    text: `公司重视与员工沟通，了解与满足员工的需求。鼓励员工通过内部平台、内部群聊与公司邮箱等渠道，向公司反馈员工的意见与诉求。亦定期开展部门沟通会和公司全员沟通会，积极了解员工真实想法和诉求，努力解决员工的需求和问题。</br>公司每年开展员工组织动力(敬业度)调研，以了解员工对公司的评价情况，并根据数据分析结果开展相关改进和提升行动计划，进一步提高公司员工敬业度。`
  }]],
  healthSafeTitle: ['职业健康与安全'],
  healthSafe: [[`秉持“安全第一，预防为主；综合治理，全员参与；科学管理，持续发展”的职业健康与安全方针，公司建立了完善的职业健康与安全管理体系，保障员工的健康与安全。</br>
		公司成立EHS管理委员会暨事故应急领导小组，由公司CEO担任组长，由各部门负责人和安全员作为成员。</br>
		为提升员工职业健康安全的意识和技能，公司开展EHS相关培训和事故演练，涵盖“EHS安全知识”“危化品管理要求”“消防安全知识”“特种设备安全”等主题，覆盖相关岗位员工或全体员工。</br>
		公司每月开展一次安全生产检查工作，每年开展一次“安全生产月”活动，加大安全生产检查力度，排查工作现场安全隐患。`, `公司制定了《危险化学品管理制度》《危化品仓库管理制度》等内部制度，对危险化学品的购买、运输、接收、贮存、领用、使用和危险废弃物处置等各环节规范操作提出要求。公司有关部门定期组织危险化学品专项检查、日常巡查和防盗防抢演练，确保制度落实及管理标准化。2022年起公司成为杭州市高新开发区（滨江）危险化学品行业会员单位。`]],
  developContinueText: ['人才发展'],
  developContinue: [[{
    title: '人才发展战略',
    text: `公司以长期可持续发展为目标，制定与企业发展目标相匹配的人才发展目标，构建人才发展战略，让组织更有成效，让员工更有成就。</br>公司借助于组织动力（敬业度）调研，全面了解员工的想法和诉求，基于调研结果制定改进提升计划，促进人才与组织协同发展。在人才队伍建设方面，公司每年开展人才盘点，并已构建人才标准能力模型，应用于招聘、培训、考核与晋升等人力资源管理全流程。`
  }, {
    title: '员工培训',
    text: `公司重视员工的培养和发展，结合内部和外部讲师资源，建立完善的员工培训体系，包括“辉计划”系列、专业能力培训、领导力培训以及面向所有员工的通用技能培训及知识科普等。</br>
			通过线上、线下或线上线下结合的方式，公司有效帮助员工实现职场人士角色转变，提升知识素养、专业能力和通用技能，帮助管理层提升领导能力。`
  }, {
    title: '员工考核与晋升',
    text: `公司建立《绩效管理及奖金政策》《职级管理体系》《竞聘答辩标准及流程》等体系制度与规范流程，明确绩效考核标准及程序，并为员工提供透明的、包含管理和专业双通道的晋升管理体系。`
  }]],
  medicalEcologyHealthTitle: ['医疗生态与社会健康赋能'],
  medicalEcologyHealthText: [{
    title: '赋能基层医疗',
    content: '自2021年起，公司与云鹊医疗科技(上海)有限公司、云鹊医疗科技(重庆)有限公司启动合作,持续推动癌症早筛观念和产品在基层医疗的普及。我们成功落地深圳市“惠民保”合作项目，由公司提供常卫清®和噗噗管®，由云鹊医疗提供基层医疗机构基层医院的服务能力，共同助力深圳市“惠民保”覆盖600万参保人的癌症早筛行动。'
  }, {
    title: '推动学术发展',
    content: '公司通过组织或参与学术和行业内的交流与合作，与专家学者、行业企业一道，推动肿瘤基础及临床诊疗研究，促进癌症早筛行业发展。自2015年成立以来，公司发起“西湖对弈暨癌症早筛与防治跨界高峰论坛”，旨在促进癌症早筛与防治领域的交流与合作。目前，该论坛已成为国内聚焦于癌症早筛最新研发、学术交流和商业创新的品牌标杆盛会。'
  }, {
    title: '提升国民健康意识',
    content: '为了促进癌症早筛知识在社会中的普及，提升公民健康意识，公司坚持在官方社交媒体平台持续进行用户教育，填补大众早筛认知空白。同时，公司每年在中国癌症早筛日等宣传日重点开展科普宣传，让更多人看到癌症早筛的价值。'
  }, {
    title: '参与政府民生工程',
    content: '由政府主导的规模化人群筛查，对干推动社会大众建立早筛意识、提高社会层面早筛覆盖率具有重要作用。公司积极支持并持续投入国家及省市区民生工程，让公司早筛产品高效、公平地惠及更广泛的人群。'
  } // `我们希望把最前沿的癌症早筛技术转化成产品，使癌症早筛技术创新能够真正落地，让更多人获益。</br>
  //  常卫清。多靶点粪便FIT-DNA检测技术已被纳入结直肠痛临床领域全部国家级指南，包括(中国结直肠痛筛查与早诊早治指南(2020.
  //  北京)》《2021版CSCO结直肠癌诊疗指南》中国肿瘤整合诊治指南CACA)》，是目前唯一合规用于结直肠高风险人群筛查的分子检
  //  测技术，将有机会为更多相关高风险人群提供癌症早期筛查技术手段。</br>
  //  为了促进癌症早筛知识在社会中的普及，提升公民健康意识，我们通过宣传日活动、“科普中国行”活动、自媒体传播等多种途径开展
  //  科普教育。`
  ],
  medicalEcologyHealth: [[{
    title: '在宣传日开展科普宣传',
    text: `公司每年重点在中国癌症早筛日（4月15日）、全民无幽日（5月15日）和世界肠道健康日（5月29日）开展关于癌症预防和早期治疗等主题的科普宣传，让更多人看到癌症早筛的价值。`
  }, // {
  // 	title: '协办结直肠癌“防筛诊治护管”科普中国行活动',
  // 	text: `“科普中国行”围绕公立医疗机构建设结直肠癌筛查科普教育基地，面向结直肠
  // 	癌患者、家属及相关的高风险人群提供及时、有效的精准科普。</br>
  // 	截至报告期末，已授牌66家结直肠癌筛查科普教育基地，培养172名“结直肠癌
  // 	科普专家”，开展患教会166场，参与人数达4,980人。`
  // },
  {
    title: '利用自媒体传递医学知识',
    text: `公司建立包括微信、视频号、抖音、微博在内的官方社交媒体矩阵，持续输出科普知识、用户故事等内容，对用户进行教育，帮助大众建立早筛意识。`
  }]],
  medicalEcologyHealthTips: ['由中国医师协会结直肠肿瘤专业委员会、中国抗癌协会大肠癌专业委员会联合发起，由《中华结直肠疾病电子杂志》提供学术支援和诺辉健康协办。'],
  policyDownTitle: ['政策文件'],
  policyDownFile: [['举报投诉管理办法', '防止舞弊与鼓励举报制度', '其他文件名称']]
}; // 绿色发展

const greenDevelopmentText = {
  environmentManageTitle: ['环境管理目标'],
  environmentManageTips: ['公司以2019年为基准，设定2025年环境管理目标如下:'],
  environmentManageText: [['综合能源消耗密度下降', '耗水密度下降', '温室气体排放密度下降', '无害废弃物排放密度降低', '有害废弃物排放密度降低']],
  environmentManageTextNum: [['15%', '20%', '20%', '60%', '20%']],
  environmentManageMethodTitle: ['环境管理措施'],
  environmentManageMethodTips: ['我们建立完善的环境管理体系，由公司EHS委员会负责监督责任部门落实环境保护工作。'],
  environmentManageMethod: [[{
    title: '能源使用',
    img: require('../../../assets/esg/energy.png'),
    text: `我们开展多种措施，以节约能源、降低损耗，提高能源使用效率。在办公方面，我们控制空调温度的设定，对员工给予节能宣导。在生产方面，我们采购节能设备并定期开展设备维修和保养，合理安排生产。此外，我们对公司的能源使用开展异常监测和内部提醒。`
  }, {
    title: '水资源使用',
    img: require('../../../assets/esg/waterResource.png'),
    text: `我们从源头和运营过程各方面进行节约用水，包括在卫生间使用感应水龙头，以及使用循环用水进行办公区域的环境清洁等。公司亦定期安排专人检查公司水池和水管的漏水情况，及时发现与修补漏水问题。`
  }, {
    title: '物料及包装物使用',
    img: require('../../../assets/esg/packaging.png'),
    text: `公司开展使用循环包装、升级仓储管理系统等措施，从源头节约资源使用、从运营过程中提高资源循环使用力度，以提高物料和包装资源利用率。对于发往杭州本地消费者的产品，公司使用物流供应商提供的循环利用包装箱进行包装，减少包装的使用量。对于冷链运输中使用的温控箱，公司增加对其的回收利用程度。</br>此外，公司开展仓储管理系统的数字化与智能化升级，通过优化仓储管理的出入库流程、多货主管理、异常预警等功能，实现物资和成品的精细化管控，提升库存利用率。`
  }, {
    title: '废弃物管理',
    img: require('../../../assets/esg/waste.png'),
    text: `公司积极采取措施减少无害废弃物的产生。例如，公司日常宣导和贯彻公司无纸化运营，对文件材料双面打印。在生产线和实验室内，公司使用环境因素自动化监测设备，减少纸质记录单的使用。在物流环节，公司逐年提高快件跟踪扫描记录仪(快递PDA)的使用环节覆盖比例，于2022年开始使用电子发货单和拣货单。</br>对于有害废弃物，分类定点收集医疗废弃物、化学试剂空瓶与试剂以及医疗废液等有害废弃物，由具有资质的第三方机构定期回收处理。`
  }, {
    title: '废水管理',
    img: require('../../../assets/esg/wasteWater.png'),
    text: `公司严格遵守《医疗机构水污染排放标准》《城镇污水处理厂污水综合排放标准》等法规标准，进行废水处理、检测和达标排放。对于生产检测污水，公司使用污水处理设备进行处理，在水质满足医疗机构水污染物排放限值的排放标准后，排入市政污水管道。`
  }]],
  climateChangeTitle: ['应对气候变化'],
  climateChangeTips: [`为了贡献国家“碳达峰·碳中和”目标的实现，贡献全球可持续发展，我们积极应对气候变化，建立气候议题治理机制，识别与公司有关的气候相关风险与机遇，评估其对公司的影响，开展全面风险管理与气候绩效改进工作，并参考ISSB《国际财务报告可持续披露准则第2号——气候相关披露》（2023）框架，从治理、策略、风险管理以及指标和目标四个核心支柱角度，开展气候信息披露。`],
  climateChangeLeft: [[['由董事会对气候变化议题承担管理责任', '公司各相关部门将气候变化减缓'], ['评估气候相关风险与机遇对公司带来的潜在运营影响与财务影响', '将气候相关风险与机遇纳入公司整体风险管理，制定应对措施']]],
  climateChangeRight: [[['识别及分析与诺辉健康相关的重大气候相关风险与机遇'], ['在ESG报告和CDP问卷中，披露能源消耗、温室气体排放等情况，评估公司应对气候变化的管理绩效水准', '结合公司现状，制定量化目标，提出并落实节能减碳方案']]],
  policyDownTitle: ['政策文件'],
  policyDownFile: [['环境、职业健康及安全政策']]
}; // 规范治理

const specsGovernText = {
  companyStructureTitle: ['公司治理架构'],
  companyStructure: [[`公司建立由股东大会、董事会和高级管理层构成的公司治理架构，并在董事会下设审核委员会、薪酬委员会和提名委员会。董事会成员背景多元，涉及理学、工学、医学和管理学等不同专业。董事会董事6名，其中，独立非执行董事3名，女性董事1名。`, `公司已建立从董事会到执行层的ESG治理架构，由董事会对ESG承担整体责任。董事会下设ESG执行工作组，负责协调各项ESG管理、沟通及信息披露工作，并向董事会汇报ESG工作进展与成果。同时，公司各部门、各地实验室及子公司根据公司各项ESG政策开展相关行动。`]],
  communicateInfoTitle: ['投资者沟通与资讯披露'],
  communicateInfo: [[`公司致力于向公司股东及其他利益相关者提供均衡且易于理解的公司资料，建立《股东通讯政策》，以促进与股东及其他利益相关者的有效沟通。`, `公司及时开展信息披露，设有通过部门主管审核、高管层审批和董事长确认的三级程式内部审批制度，以保障信息披露的准确性和规范性。公司通过在交易所发布公告及其他文件、公司网站、股东大会等渠道进行信息披露，并通过业绩说明会、投资者活动、股东查询、电话、邮箱和办公现场等方式与投资者保持密切沟通。`]],
  complianceBusinessEthicsTitle: ['合规与商业道德'],
  complianceBusinessEthics: [[{
    title: '合规体系建设',
    text: [`公司严格遵循「全面覆盖、全员合规、坚守底线、违规必究」的管理原则，不断优化和完善合规体系，以促进公司的稳健运营和持续发展。公司建立《合规管理制度》及《合规手册》等内部制度，对公司合规经营管理实施风险控制和管理。`, `公司要求全体员工签署《合规承诺书》，并定期参加合规培训。公司设置合规奖励与问责体系，对严格执行合规规定、避免发生重大违规风险或对于减少损失有直接贡献的员工给予表影;对有任何违法、违纪和违规行为的员工进行问责，若涉嫌犯罪，公司将依法追究其法律责任。`]
  }, {
    title: '商业道德',
    text: [`公司重视商业道德建设，积极开展商业道德培训工作，预防和控制舞弊、贪污、贿赂、洗钱、不正当竞争、利益冲突、信息泄露等事件的发生。`, `公司在官网设置“社会监督”页面，合作伙伴或员工若发现公司存在舞弊、贿赂、信息泄露、侵占资产、勒索或洗钱等现象，可通过监督举报渠道进行反馈。公司根据《投诉举报管理制度》受理投诉和举报，对投诉举报人员信息和材料实行保密制度，并严禁任何单位、部门或个人以任何借口阻拦、压制投诉举报人员或者打击报复投诉举报人员。`]
  }]],
  policyDownTitle: ['政策文件'],
  policyDownFile: [['举报投诉管理办法', '防止舞弊与鼓励举报制度', '其他文件名称']]
}; // 认可与荣誉

const recognitionHonorsText = {
  qualificationCertificationTitle: ['资质认证'],
  qualificationCertification: [['', '', '', '']],
  ESGHonorTitle: ['ESG荣誉'],
  ESGHonor: [[{
    title: '2023年度华夏大健康ESG优秀企业',
    subtitle: '华夏时报',
    img: require('../../../assets/esg/best.png'),
    url: 'https://mbd.baidu.com/newspage/data/landingsuper?rs=4226726936&ruk=IRQliqnPnY_zSzlzUdfSZQ&urlext=%7B%22cuid%22%3A%22givKu_aJv8_AuBaR_OHOtYu_HuYWuv8O08HhiguLvi_cOH8Rga2K8_ir3O5381aG00ImA%22%7D&isBdboxFrom=1&pageType=1&sid_for_share=&context=%7B%22nid%22%3A%22news_9685297844019325223%22,%22sourceFrom%22%3A%22search%22%7D'
  }, {
    title: '2023年度最佳ESG公司奖-诺辉健康、最佳CEO奖-朱叶青',
    subtitle: '智通财经',
    img: require('../../../assets/esg/bestESG.png'),
    url: 'https://baijiahao.baidu.com/s?id=1784503298767914487&wfr=spider&for=pc'
  }, {
    title: '2023中国医药上市公司ESG竞争力TOP20',
    subtitle: 'E药经理人',
    img: require('../../../assets/esg/yiyao.jpg'),
    url: 'https://baijiahao.baidu.com/s?id=1780151272199413152&wfr=spider&for=pc'
  }, {
    title: '2023年度科技普惠奖',
    subtitle: '虎嗅',
    img: require('../../../assets/esg/huxiu.png'),
    url: 'https://mp.weixin.qq.com/s/xBfAPfgm6Dz7dwrchKVuTA'
  }]]
}; // esg报告

const esgReportText = {
  esgReportTitle: ['ESG报告下载'],
  esgReport: [[{
    title: '2021年度报告',
    url: '',
    img: require('../../../assets/esg/report2021.png')
  }, {
    title: '2022年度报告',
    url: '',
    img: require('../../../assets/esg/report2022.png')
  }, {
    title: '2023年度报告',
    url: '',
    img: ''
  }]]
}; // esg头部tab

const esgList = [[{
  title: '绿色发展',
  img: require('../../../assets/esg/greenDevelop.png'),
  components: 'GreenDevelopment'
}, {
  title: '社会责任',
  img: require('../../../assets/esg/socialResponsibility.png'),
  components: 'SocialResponsibility'
}, {
  title: '规范治理',
  img: require('../../../assets/esg/standardGovern.png'),
  components: 'SpecsGovern'
}, {
  title: 'ESG报告',
  img: require('../../../assets/esg/esgReportTab.png'),
  components: 'EsgReport'
}, {
  title: '认可与荣誉',
  img: require('../../../assets/esg/approveHonor.png'),
  components: 'RecognitionHonors'
}]];
export { socialResponsibilityText, greenDevelopmentText, specsGovernText, recognitionHonorsText, esgReportText, esgList };