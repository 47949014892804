var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "module-box animate__animated animate__fadeInUp animate__slow"
  }, [_c("header-title", {
    attrs: {
      title: _vm.recognitionHonorsText.qualificationCertificationTitle[_vm.language - 1]
    }
  }), _c("div", {
    staticClass: "qualification-certification"
  }, [_c("div", {
    staticClass: "qualification-certification_list"
  }, _vm._l(_vm.honorList, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        width: "20%"
      }
    }, [_c("div", {
      staticClass: "qualification-certification_list_item"
    }, [_c("img", {
      attrs: {
        src: _vm.title + item.honorImage,
        alt: ""
      }
    })]), _c("div", {
      staticStyle: {
        "text-align": "center"
      }
    }, [_c("span", [_vm._v(_vm._s(item.honorPic))])])]);
  }), 0), _c("div", {
    staticClass: "qualification-certification_bottom"
  })]), _c("header-title", {
    attrs: {
      title: _vm.recognitionHonorsText.ESGHonorTitle[_vm.language - 1]
    }
  }), _c("div", {
    staticClass: "esg-honor"
  }, _vm._l(_vm.recognitionHonorsText.ESGHonor[_vm.language - 1], function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "esg-honor_item"
    }, [_c("div", {
      staticClass: "esg-honor_item_circle"
    }), _c("img", {
      attrs: {
        src: item.img,
        alt: ""
      }
    }), _c("div", {
      staticClass: "esg-honor_item_right"
    }, [_c("div", {
      staticClass: "esg-honor_item_right_top"
    }, [_c("div", {
      staticClass: "esg-honor_item_right_top_title"
    }, [_vm._v(_vm._s(item.title))]), _c("div", {
      staticClass: "esg-honor_item_right_top_read",
      staticStyle: {
        "margin-left": "5px",
        cursor: "pointer"
      },
      on: {
        click: function ($event) {
          return _vm.handleView(item);
        }
      }
    }, [_c("span", [_vm._v("点击阅读")]), _c("span", [_vm._v(">>")])])]), _c("div", {
      staticClass: "esg-honor_item_right_line"
    }), _c("div", {
      staticClass: "esg-honor_item_right_subtitle"
    }, [_vm._v(_vm._s(item.subtitle))])])]);
  }), 0), _c("el-dialog", {
    attrs: {
      title: `${_vm.imgTitle}预览`,
      visible: _vm.dialogVisible,
      width: "50%",
      top: "10vh",
      "append-to-body": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("el-image", {
    staticStyle: {
      width: "300px",
      height: "300px"
    },
    attrs: {
      src: _vm.dialogImageUrl,
      "preview-src-list": [_vm.dialogImageUrl]
    }
  })], 1)])], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };