var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "module-box animate__animated animate__fadeInUp animate__slow"
  }, [_c("header-title", {
    attrs: {
      title: _vm.greenDevelopmentText.environmentManageTitle[_vm.language - 1]
    }
  }), _c("div", {
    staticClass: "environment-manage_tips"
  }, [_vm._v(_vm._s(_vm.greenDevelopmentText.environmentManageTips[_vm.language - 1]))]), _c("div", {
    staticClass: "environment-manage"
  }, [_c("img", {
    attrs: {
      src: require("../../../assets/esg/environmentManage.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "environment-manage_left"
  }), _c("div", {
    staticClass: "environment-manage_text"
  }, [_c("div", {
    staticClass: "environment-manage_text_item"
  }, _vm._l(_vm.greenDevelopmentText.environmentManageText[_vm.language - 1], function (item, index) {
    return _c("div", {
      key: index
    }, [_c("span", [_vm._v(_vm._s(item))]), _c("span", {
      staticClass: "environment-manage_text_item_num"
    }, [_vm._v(_vm._s(_vm.greenDevelopmentText.environmentManageTextNum[_vm.language - 1][index]))])]);
  }), 0)])]), _c("header-title", {
    attrs: {
      title: _vm.greenDevelopmentText.environmentManageMethodTitle[_vm.language - 1]
    }
  }), _c("div", {
    staticClass: "environment-manage-method_tips"
  }, [_vm._v(_vm._s(_vm.greenDevelopmentText.environmentManageMethodTips[_vm.language - 1]))]), _c("div", {
    staticClass: "environment-manage-method"
  }, _vm._l(_vm.greenDevelopmentText.environmentManageMethod[_vm.language - 1], function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "environment-manage-method_item"
    }, [_c("div", {
      staticClass: "environment-manage-method_item_left"
    }, [_c("div", {
      staticClass: "img"
    }, [_c("img", {
      attrs: {
        src: item.img,
        alt: ""
      }
    })]), _c("div", {
      staticClass: "environment-manage-method_item_left_title"
    }, [_vm._v(_vm._s(item.title))])]), _c("div", {
      staticClass: "environment-manage-method_item_text",
      domProps: {
        innerHTML: _vm._s(item.text)
      }
    })]);
  }), 0), _c("header-title", {
    attrs: {
      title: _vm.greenDevelopmentText.climateChangeTitle[_vm.language - 1]
    }
  }), _c("div", {
    staticClass: "climate-change_tips"
  }, [_vm._v(_vm._s(_vm.greenDevelopmentText.climateChangeTips[_vm.language - 1]))]), _c("div", {
    staticClass: "climate-change"
  }, [_c("div", {
    staticClass: "climate-change_left"
  }, _vm._l(_vm.greenDevelopmentText.climateChangeLeft[_vm.language - 1], function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "climate-change_left_list"
    }, _vm._l(item, function (item1, i) {
      return _c("div", {
        key: i,
        staticClass: "climate-change_left_list_item"
      }, [_c("div", [_vm._v(" " + _vm._s(item1))])]);
    }), 0);
  }), 0), _c("img", {
    attrs: {
      src: require("../../../assets/esg/climateChange.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "climate-change_left"
  }, _vm._l(_vm.greenDevelopmentText.climateChangeRight[_vm.language - 1], function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "climate-change_left_list"
    }, _vm._l(item, function (item1, i) {
      return _c("div", {
        key: i,
        staticClass: "climate-change_left_list_item"
      }, [_c("div", [_vm._v(" " + _vm._s(item1))])]);
    }), 0);
  }), 0)]), _c("header-title", {
    attrs: {
      title: _vm.greenDevelopmentText.policyDownTitle[_vm.language - 1]
    }
  }), _c("div", {
    staticClass: "policy-down"
  }, _vm._l(_vm.policyList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "policy-down_item"
    }, [_c("img", {
      staticClass: "policy-down_item_icon",
      attrs: {
        src: require("../../../assets/esg/download.png")
      }
    }), _c("el-link", {
      attrs: {
        type: "success"
      }
    }, [_c("span", {
      staticClass: "policy-down_item_text",
      on: {
        click: function ($event) {
          return _vm.viewPdf(item.honorUrl);
        }
      }
    }, [_vm._v(_vm._s(item.honorPic))])])], 1);
  }), 0)], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };