import HeaderTitle from './title.vue';
import { mapState } from 'vuex';
import { socialResponsibilityText } from '../config/index';
export default {
  components: {
    HeaderTitle
  },

  data() {
    return {
      socialResponsibilityText,
      policyList: [],
      title: this.$api.api_title,
      medicalTextList: []
    };
  },

  computed: { ...mapState({
      language: state => state.language
    })
  },

  mounted() {
    this.getPolicyList();
    this.getMedicalText();
  },

  methods: {
    getMedicalText() {
      this.$http.get(this.$api.esg_medicalList).then(res => {
        if (res.code == 0 && res.data) {
          this.medicalTextList = res.data;
        }
      });
    },

    getPolicyList() {
      this.$http.get(this.$api.esg_honorList).then(res => {
        if (res.code == 0 && res.data) {
          res.data.forEach(item => {
            if (item.honorType == 2) this.policyList.push(item);
          });
        }
      });
    },

    viewPdf(link) {
      window.open(this.title + link + '#toolbar=0', '_blank');
    }

  }
};