var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "module-box animate__animated animate__fadeInUp animate__slow"
  }, [_c("header-title", {
    attrs: {
      title: _vm.specsGovernText.companyStructureTitle[_vm.language - 1]
    }
  }), _c("div", {
    staticClass: "company-structure"
  }, _vm._l(_vm.specsGovernText.companyStructure[_vm.language - 1], function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "company-structure_text"
    }, [_vm._v(" " + _vm._s(item))]);
  }), 0), _c("header-title", {
    attrs: {
      title: _vm.specsGovernText.communicateInfoTitle[_vm.language - 1]
    }
  }), _c("div", {
    staticClass: "communicate-info"
  }, _vm._l(_vm.specsGovernText.communicateInfo[_vm.language - 1], function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "communicate-info_text"
    }, [_vm._v(_vm._s(item))]);
  }), 0), _c("header-title", {
    attrs: {
      title: _vm.specsGovernText.complianceBusinessEthicsTitle[_vm.language - 1]
    }
  }), _c("div", {
    staticClass: "compliance-business-ethics"
  }, _vm._l(_vm.specsGovernText.complianceBusinessEthics[_vm.language - 1], function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "compliance-business-ethics_item"
    }, [_c("span", {
      staticClass: "compliance-business-ethics_item_title"
    }, [_vm._v(_vm._s(item.title))]), _vm._l(item.text, function (text, i) {
      return _c("div", {
        key: i,
        staticClass: "compliance-business-ethics_item_text"
      }, [_vm._v(_vm._s(text))]);
    })], 2);
  }), 0), _c("header-title", {
    attrs: {
      title: _vm.specsGovernText.policyDownTitle[_vm.language - 1]
    }
  }), _c("div", {
    staticClass: "policy-down"
  }, _vm._l(_vm.policyList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "policy-down_item"
    }, [_c("img", {
      staticClass: "policy-down_item_icon",
      attrs: {
        src: require("../../../assets/esg/download.png")
      }
    }), _c("el-link", {
      attrs: {
        type: "success"
      }
    }, [_c("span", {
      staticClass: "policy-down_item_text",
      on: {
        click: function ($event) {
          return _vm.viewPdf(item.honorUrl);
        }
      }
    }, [_vm._v(_vm._s(item.honorPic))])])], 1);
  }), 0)], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };