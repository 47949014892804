import HeaderTitle from './title.vue';
import { mapState } from 'vuex';
import { recognitionHonorsText } from '../config/index';
export default {
  components: {
    HeaderTitle
  },

  data() {
    return {
      recognitionHonorsText,
      dialogVisible: false,
      dialogImageUrl: '',
      imgTitle: '',
      honorList: [],
      title: this.$api.api_title
    };
  },

  computed: { ...mapState({
      language: state => state.language
    })
  },

  mounted() {
    this.getHonorList();
  },

  methods: {
    handleView(data) {
      window.open(data.url); // this.dialogVisible = true
      // this.imgTitle = data.title
      // this.dialogImageUrl = data.img
    },

    getHonorList() {
      this.$http.get(this.$api.esg_honorList).then(res => {
        if (res.code == 0 && res.data) {
          res.data.forEach(item => {
            if (item.honorType == 4) this.honorList.push(item);
          });
        }
      });
    },

    viewPdf(link) {
      window.open(this.title + link + '#toolbar=0', '_blank');
    }

  }
};